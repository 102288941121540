import React from 'react'
import { graphql } from 'gatsby'
import orderBy from 'lodash/orderBy'
import Helmet from 'react-helmet'
import moment from 'moment'
import config from '../utils/siteConfig'
import Layout from '../components/Layout'
import Card from '../components/Card'
import CardReverse from '../components/CardReverse'
import CardList from '../components/CardList'
import CategoryPageTitle from '../components/CategoryPageTitle'
import Pagination from '../components/Pagination'
import Container from '../components/Container'
import OutfitBanner from '../components/OutfitBanner'
import BeautyProductBanner from '../components/BeautyProductBanner'

const CategoryTemplate = ({ data, pageContext }) => {
  const posts = orderBy(
    data.contentfulCategory.blog_post,
    // eslint-disable-next-line
    [object => new moment(object.publishedDateISO)],
    ['desc']
  )

  const { categoryName, slug } = data.contentfulCategory
  const skip = pageContext.skip
  const limit = pageContext.limit
  const currentPage = pageContext.currentPage
  const isFirstPage = currentPage === 1

  return (
    <Layout>
      {isFirstPage ? (
        <Helmet>
          <title>{`${config.siteTitle} | Category - ${categoryName}`}</title>
          <meta
            property="og:title"
            content={`${config.siteTitle} | Category - ${categoryName}`}
          />
          <meta
            property="og:url"
            content={`${config.siteUrl}/category/${slug}/`}
          />
        </Helmet>
      ) : (
        <Helmet>
          <title>{`${
            config.siteTitle
          } | Category - ${categoryName} | Page ${currentPage}`}</title>
          <meta
            property="og:title"
            content={`${
              config.siteTitle
            } | Category - ${categoryName} | Page ${currentPage}`}
          />
          <meta
            property="og:url"
            content={`${config.siteUrl}/category/${slug}/`}
          />
        </Helmet>
      )}

      <Container>
        <CategoryPageTitle small>{categoryName}</CategoryPageTitle>

        <CardList>
          {/* posts.slice(skip, limit * currentPage).map(post => (
            <Card {...post} key={post.id} />
          )) */}
          {posts.slice(skip, limit * currentPage).map((post, index) =>
            index % 2 === 0 ? (
              <Card key={post.id} {...post} />
            ) : (
              <div key={index}>
                <CardReverse key={post.id} {...post} />
                {index === 1
                  ? categoryName === 'Fashion' && <OutfitBanner />
                  : categoryName === 'Beauty' && <BeautyProductBanner />}
              </div>
            )
          )}
        </CardList>
      </Container>
      <Pagination context={pageContext} />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulCategory(slug: { eq: $slug }) {
      categoryName
      id
      slug
      blog_post {
        id
        title
        slug
        category {
          slug
          categoryName
        }
        publishedDate(formatString: "MMMM DD, YYYY")
        publishedDateISO: publishedDate(formatString: "YYYY-MM-DD")
        featuredImage {
          title
          fluid(maxWidth: 1050, cropFocus: FACES, resizingBehavior: FILL) {
            ...GatsbyContentfulFluid_withWebp
          }
          file {
            url
          }
        }
        content {
          childMarkdownRemark {
            html
            excerpt(pruneLength: 450)
          }
        }
      }
    }
  }
`

export default CategoryTemplate

import React from 'react'
import styled from 'styled-components'

const Title = styled.h1`
  font-size: ${props => (props.small ? '2em' : '3em')};
  font-family: 'Signature', cursive;
  font-weight: 300;
  font-size: 5rem;
  text-align: center;
  margin: 8rem 0 4rem 0;
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    margin: 3rem 0 3rem 0;
  }
  line-height: 1.2;
  span {
    margin: 0 0 0 0.25em;
  }
  a {
    transition: all 0.2s;
    color: ${props => props.theme.colors.base};
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
  }
`

const PageTitle = props => {
  return <Title small={props.small}>{props.children}</Title>
}

export default PageTitle
